<template>
    <header class="TopBar">
        <router-link :to="`/collections/${collectionSlug}`" class="TopBar_Item TopBar_Item--left"><icon name="chevron-left" /> BACK</router-link>

        <h1 class="TopBar_Item TopBar_Item--center PageTitle">NEW PUZZLE</h1>
    </header>
    <main class="view view--work">
        <h2 class="ContentTitle">{{ getTitle(work.artObject) }}</h2>
        <!-- <p>
            {{ collection.description }}
        </p> -->

        <form @submit.prevent="createGame" class="PuzzleForm">
            <div class="PuzzleCover">
                <img v-if="work.artObject.webImage"
                    class="PuzzleCover_image"
                    ref="coverImage"
                    :src="`/highres/${work.artObject.objectNumber}.jpg`"
                    :width="work.artObject.webImage.width"
                    :height="work.artObject.webImage.height" />

                <span v-if="grid" class="game-display-image-grid game-display-image-grid--horizontal"
                :style="{ 'background-size': (100/grid[0]) + '%' }"/>
                <span v-if="grid" class="game-display-image-grid game-display-image-grid--vertical"
                :style="{ 'background-size': 'auto ' + (100/grid[1]) + '%' }"/>
            </div>


            <label>Size:</label>
            <select v-model="gridIndex">
                <option v-for="(g, i) in gridOptions" :key="i" :value="i">{{g[0]*g[1]}} pieces  ({{g[0]}} ⨯ {{g[1]}})</option>
            </select>

            <button>
                PLAY!
            </button>
        </form>
        
    </main>
</template>

<script>
import { ref, toRefs, toRef, reactive, onMounted, onUnmounted, watch, computed} from 'vue';
import { useRouter } from 'vue-router';


import Camera from "../game/camera.js";
import * as Input from "../game/input.js";
import * as Puzzle from "../game/puzzle.js";
import * as Mesh from "../game/mesh-generators.js";

import { getTitle } from "../lib/works.js";

import data from "../assets/data/data.json";
import collections from "../assets/data/collections.json";

window.data = data;

export default {
    name: "WorkView",
    props: [ "collectionSlug", "workId" ],
    setup(props, ctx) {
        let router = useRouter();

        let collection = computed(() => collections.find(c => c.slug == props.collectionSlug));
        let collectionWorks = computed(() => collection.value.ids.filter(id => id in data).map(id => data[id]));
        let work = computed(() => data[props.workId]);

        let coverImage = ref(null);
        let imageDim = computed(() => work.value ? [ work.value.artObject.webImage.width, work.value.artObject.webImage.height ] : [ 0, 0 ] );

        let gridIndex = ref(0);
        let gridOptions = computed(() => {
            if (!work.value) return [];

            let dim = imageDim.value;
            return [ 5, 10, 15 ].map(c => {
                let gridDim;
                if (dim[X] < dim[Y]) {
                    let aspect = dim[X]/dim[Y];
                    gridDim = [ ceil(c*aspect), c ];
                } else {
                    let aspect = dim[Y]/dim[X];
                    gridDim = [ c, ceil(c*aspect) ];
                }
                return gridDim;
            });
        });
        let grid = computed(() => gridOptions.value[gridIndex.value]);


        let existingGames = JSON.parse(localStorage["masterpieces_games"] || "[]");
        let createGame = () => {
            let id = "";
            do id += round(random()*0xff).toString(16); while (existingGames.includes(id));
            
            let dim = imageDim.value;

            let cellDim = div(dim, grid.value);
            let minCellDim = min(...cellDim);
            const seed = getSeed();
            let mesh = Mesh.generateCubicRegularMesh(dim, grid.value, minCellDim/16, minCellDim/16, seed);
            let pieceData = Puzzle.buildPieceData(coverImage.value, mesh, minCellDim/4, minCellDim/4);
            let chunks = Puzzle.initializeChunks(pieceData);
            Puzzle.shuffleChunks(chunks, mesh.boundingDim);


            let puzzle = {
                id,
                created: Date.now(),
                work: work.value.artObject.objectNumber,
                meshSpec: {
                    seed, dim, gridDim: grid.value, perturb: minCellDim/16, perturbControl: minCellDim/16,
                },
            };

            Puzzle.save(puzzle, chunks);

            router.push(`/puzzle/${id}`);
            existingGames.push(id);
            localStorage["masterpieces_games"] = JSON.stringify(existingGames);
        };

        return { collection, collectionWorks, work, grid, gridIndex, gridOptions, createGame, coverImage, getTitle };
    },
}
</script>

<style lang="scss">

label { display: block; color: black;}
input, select {
    display: block;
    margin-bottom: 6px;
}

.PuzzleForm {
    background: white;
    padding: 6px;
    margin: 12px 0;
    border-radius: 4px;
    max-width: 800px;
}

.PuzzleCover {
    position: relative;
}

.PuzzleCover_image {
    display: block;;
    width: 100%; height: auto;
    margin-bottom: 12px;
}
</style>