<template>
    <header class="TopBar">
        <button @click="goBack" class="TopBar_Item TopBar_Item--left">
            <icon name="chevron-left" /> BACK
        </button>

        <h1 class="TopBar_Item TopBar_Item--center PageTitle">CONFIG</h1>
    </header>
    <main class="view view--config">


        <section>
            <h3>Puzzling</h3>
            <label>
                Background:
            </label>
            <label class="RadioLabel">
                Dark
                <input
                    type="radio" value="transparent" name="background" v-model="background" />
            </label>
            <label class="RadioLabel">
                Light
                <input type="radio" value="#eee" name="background" v-model="background" />
            </label>

                <!-- <button class="TextButton">Choose color</button> -->
        </section>
        <section>
            <h3>Audio</h3>
            <label>
                Volume:
                <input type="range" min="0" max="100" step="1" v-model="gain" @change="updateGain" />
                <input type="number" min="0" max="100" step="1" v-model="gain" @change="updateGain" />
            </label>
        </section>
    </main>
</template>

<script>
import { inject, ref, toRef } from 'vue';
import * as Sound from "../game/sound.js";

export default {
    setup() {
        let sound = inject('sound');
        let gain = ref(floor(100*sound.master.gain.value));

        let userPreferences = inject("userPreferences");
        let background = ref(userPreferences.background);

        return {
            sound,
            gain,
            _setGain: inject("setGain"),
            userPreferences,
            background,
            setBackgroundPreferences: inject("setBackgroundPreferences"),
            setDifficultyPreferences: inject("setDifficultyPreferences"),
        };
    },
    methods: {
        goBack() { this.$router.go(-1); },
        updateGain() {
            this._setGain(this.gain);
            Sound.playPlop(this.sound);
        },
    },
    watch: {
        background(val) { this.setBackgroundPreferences(val); },
    }
}
</script>

<style lang="scss">

.view.view--config {
    background: #eee;
    max-width: 512px;
    margin: 0 auto;

    display: block;
    padding: 24px;

    label {
        display: block;
    }

    input { display: inline-block; margin: 6px; }

    input[type=range] { vertical-align: top; }
    input[type=number] { width: 6ch; text-align: right; }

    h3 {
        color: #222;
    }
}

</style>