<template>
  <router-link
    class="PuzzleTile"
    :class="[
      game.completion == 1 ? 'PuzzleTile--complete' : 'PuzzleTile--incomplete',
      `PuzzleTile--${vari}`,
    ]"
    :to="`/puzzle/${game.id}`"
  >
    <img :src="`/256_auto/${game.work}.jpg`" class="PuzzleTile_cover" />
    <span class="PuzzleTile_title">{{ title }}</span>

    <span class="PuzzleTile_completion">
      <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="Completion_Graphic"
      >
        <circle
          cx="60"
          cy="60"
          r="48"
          fill="rgba(255,255,255, .4)"
          stroke="rgba(255,255,255, .75)"
          stroke-opacity="1"
          stroke-width="4"
        ></circle>
        <g filter="url(#filter0_d)">
          <circle
            cx="60"
            cy="60"
            r="48"
            _stroke="#43BC5D"
            stroke="goldenrod"
            stroke-width="12"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-dasharray="301.59289474462014"
            :stroke-dashoffset="
              300.59289474462014 - game.completion * 301.59289474462014
            "
            _stroke-dashoffset="300.59289474462014"
            transform="rotate(-90 60 60)"
          ></circle>
          <circle
            cx="60"
            cy="60"
            r="48"
            stroke="url(#paint1_angular)"
            stroke-width="12"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-dasharray="301.59289474462014"
            :stroke-dashoffset="
              300.59289474462014 - game.completion * 301.59289474462014
            "
            transform="rotate(-90 60 60)"
          ></circle>
          <circle
            cx="60"
            cy="60"
            r="48"
            stroke="url(#paint2_angular)"
            stroke-width="12"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-dasharray="301.59289474462014"
            :stroke-dashoffset="
              300.59289474462014 - game.completion * 301.59289474462014
            "
            _stroke-dashoffset="300.59289474462014"
            transform="rotate(-90 60 60)"
          ></circle>
        </g>
        <defs>
          <filter
            id="filter0_d"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feOffset dy="2"></feOffset>
            <feGaussianBlur stdDeviation="2"></feGaussianBlur>
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            ></feColorMatrix>
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            ></feBlend>
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            ></feBlend>
          </filter>
          <radialGradient
            id="paint1_angular"
            cx="-.5"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(49 56) rotate(-90) scale(46)"
          >
            <stop stop-color="#43BC5D"></stop>
            <stop offset="0.463542" stop-color="#E8CC3Bcc"></stop>
            <stop offset="1" stop-color="#85C53400"></stop>
          </radialGradient>
          <radialGradient
            id="paint2_angular"
            cx=".4"
            cy="-0.1"
            r=".9"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(49 56) rotate(-90) scale(46)"
          >
            <stop offset="0.6917" stop-color="#2BE3D8cc"></stop>
            <stop offset="1" stop-color="#85C53400"></stop>
          </radialGradient>
        </defs>
      </svg>
      <span class="PuzzleTile_CompletionText">
        <span class="PuzzleTile_completionLabel">Completion:</span>
        {{ Math.round(100 * game.completion) }}<sup>%</sup>
      </span>
    </span>
  </router-link>
</template>

<script>
import works from "@/assets/data/data.json";

import { getTitle } from "@/lib/works.js";

export default {
  props: ["game", "variation"],
  computed: {
    title() {
      if (this.game) return getTitle(works[this.game.work].artObject);
    },
    work() {
      if (this.game) return works[this.game.work];
    },
    vari() {
      return this.variation || "normal";
    },
  },
};
</script>

<style lang="scss">
.PuzzleTile {
  position: relative;
  display: block;
  width: 300px;
  height: 212.13px;
  border: 2px solid white;
  overflow: hidden;

  color: #222;
  text-decoration: none;
  background: palegoldenrod;
  border-radius: 2px;
  text-shadow: 0 1px 0 rgba(palegoldenrod, 0.2);

  &.PuzzleTile--small {
    width: 120px;
    height: 120px;
  }
}

.PuzzleTile_cover {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
  opacity: 0.9;
}

.PuzzleTile_title {
  position: relative;
  display: block;
  margin: 10px 16px;
  padding: 2px 8px;
  width: calc(100% - 48px);
  text-align: center;
  white-space: nowrap;

  background: rgba(white, 0.8);

  font-size: 18px;

  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 4px;
  border-bottom: 1px solid #444;

  .PuzzleTile--small & {
    position: absolute;
    visibility: hidden;
  }
}

.PuzzleTile_completion {
  position: absolute;
  bottom: 12px;
  right: 12px;
  font-size: 24px;
  font-weight: bold;

  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;

  sup {
    font-size: small;
  }

  .PuzzleTile--small & {
    bottom: 50%;
    right: 50%;
    transform: translate(50%, 50%);

  }
}
.PuzzleTile_CompletionText {
  position: relative;
  line-height: 0;
}
.PuzzleTile_completionLabel {
  visibility: hidden;
  font-size: 0;
}

.Completion_Graphic {
  position: absolute;
  width: 100%; height: 100%;
  bottom: 0; left: 0;
}
</style>