<template>
    <canvas ref="canvas" class="PuzzleCanvas" :style="{
        'background-color': userPreferences.background
    }" />
    <div class="HUD" :class="`HUD--${completion == 100 ? 'inactive' : 'active'}`" v-if="work">
        <header class="TopHUD">
            <router-link to="/" class="button HudHome"><img src="/icon.svg" /></router-link>
            <span class="HUD_Title">{{title}} {{completion}}%</span>

            
            <div>
                <button @mousedown.stop @mouseup.stop @click="goTo('/config')" class="button HudButton HudButton--shuffle">
                    <span class="HudButton_Icon"><Icon name="settings-2" /></span>
                    <span class="HudButton_Label">settings</span>
                </button>
                <button @mousedown.stop @mouseup.stop @click="toggleCover" class="button HudButton HudButton--shuffle">
                    <span class="HudButton_Icon"><Icon name="image" /></span>
                    <span class="HudButton_Label">picture</span>
                </button>
            </div>
        </header>

        <footer class="BottomHUD">
            <button @mousedown.stop @mouseup.stop @click="shuffle" class="button HudButton HudButton--shuffle">
                <span class="HudButton_Icon"><Icon name="shuffle-2" /></span>
                <span class="HudButton_Label">shuffle</span>
            </button>

            <div>
                <button @mousedown.stop @mouseup.stop @click="zoomIn" class="button HudButton HudButton--shuffle">
                    <span class="HudButton_Icon"><Icon name="maximize" /></span>
                    <span class="HudButton_Label">zoom in</span>
                </button>
                <button @mousedown.stop @mouseup.stop @click="zoomOut" class="button HudButton HudButton--shuffle">
                    <span class="HudButton_Icon"><Icon name="minimize" /></span>
                    <span class="HudButton_Label">zoom out</span>
                </button>
                <button @mousedown.stop @mouseup.stop @click="fitScreen" class="button HudButton HudButton--shuffle">
                    <span class="HudButton_Icon"><Icon name="expand" /></span>
                    <span class="HudButton_Label">fit screen</span>
                </button>
            </div>
        </footer>
    </div>
    <div class="HUD VictoryHUD" :class="`HUD--${completion == 100 ? 'active' : 'inactive'}`" v-if="work">
        <section class="VictoryMessage">
            <h2>You did it! 🎉</h2>
            <p>You completed the puzzle!</p>

            <router-link class="btn" to="/">Exit</router-link>
        </section>
    </div>

    <div class="CoverOverlay" :class="`CoverOverlay--${showCover ? 'show' : 'hide'}`" v-if="work" @click.stop="showCover = false" @mousedown.stop @mouseup.stop>
        <img class="CoverOverlay_Image"  :src="`/highres/${work.artObject.objectNumber}.jpg`"  />
    </div>

</template>

<script>
import { ref, toRefs, toRef, reactive, onMounted, onUnmounted, watch, handleError, computed, inject } from 'vue';

import { getTitle } from "../lib/works.js";

import * as Camera from "../game/camera.js";
import * as Input from "../game/input.js";
import * as Utils from "../game/utils.js";
import * as Puzzle from "../game/puzzle.js";
import * as Sound from "../game/sound.js";



import works from "../assets/data/data.json";


let zoomFactor = 1.5;

export default {
    name: "PuzzleView",
    props: [ "id" ],
    setup(props, {}) {
        let canvas = ref(null);
        let ctx;


        let sound;
        let frameHandle, lastT;
        let update = t => {
            frameHandle = requestAnimationFrame(update);
            let dt = t - lastT;

            try {
                let input = {
                    t, dt,
                    mouse: Input.currentMouse, 
                    lastMouse: Input.lastMouse, 
                    mouseDelta: Input.getDelta()
                };
    
                Puzzle.updatePuzzle(ctx, sound, input);
    
    
                lastT = t;
                Input.endFrame();
            } catch (e)  {
                debugger;
                cancelAnimationFrame(frameHandle);
            }
        }

        let work = ref(null);
        let title = computed(() => work.value ? getTitle(work.value.artObject) : "");
        let completion = ref(0);
        let puzzleEvents = {
            "MP_connect": () => completion.value = round(100*Puzzle.calculateCompletion({ puzzle: Puzzle.activeGame, chunks: Puzzle.chunks }))
        };

        let shuffle = () => {
            if (Puzzle.chunks) {
                Puzzle.shuffleChunks(Puzzle.chunks, Camera.unproject(scl(Camera.viewDim, 1.1), Camera.transform));
                Sound.playSFX(sound, "acoustic_shuffle2");
            }
        };


        onMounted(() => {
            Utils.registerEventHandlers(puzzleEvents);
            Input.initialize(window);
            Promise.all([
                Puzzle.load(props.id),
                inject("sfxPromise").then(s => sound = s)
            ])
                .then(() => {
                    completion.value = round(100*Puzzle.calculateCompletion({ puzzle: Puzzle.activeGame, chunks: Puzzle.chunks }));
                    work.value = works[Puzzle.activeGame.work];
                    Camera.initialize(canvas.value, Puzzle.puzzleMesh);
                    ctx = canvas.value.getContext("2d");
                    lastT = performance.now();
                    frameHandle = requestAnimationFrame(update);
                });
        });

        onUnmounted(() => {
            Utils.removeEventHandlers(puzzleEvents);
            cancelAnimationFrame(frameHandle);
            Input.destroy();
            Camera.destroy();
        });

        let showCover = ref(false);

        return {
            canvas, completion, shuffle, work, title, showCover,
            userPreferences: inject("userPreferences"),
        };
    },

    methods: {
        toggleCover() { this.showCover = true; },
        zoomIn()  { Camera.zoomAt(scl(Camera.viewDim, 0.5),   zoomFactor); },
        zoomOut() { Camera.zoomAt(scl(Camera.viewDim, 0.5), 1/zoomFactor); },
        fitScreen() {
            Camera.fitScreen(Puzzle.puzzleMesh)
        },
        goTo(slug) { this.$router.push(slug); }
    }
}
</script>

<style lang="scss">

.PuzzleCanvas {
    position: fixed;
    top: 0; left: 0;
    width: 100%; height: 100%;
}

.HUD {
    position: fixed;
    display: flex;
    width: 100%; height: 100%;
    flex-direction: column;
    justify-content: space-between;

    user-select: none;

    transition: opacity 1s ease;
    
    &.HUD--inactive {
        opacity: 0;
        pointer-events: none;
    }
}

.TopHUD, .BottomHUD {
    padding: 12px;
    display: flex;
    justify-content: space-between;
}

.BottomHUD {
    align-items: flex-end;
}

.HUD_Title {
    justify-self: center;
}

.HudHome {
    padding: 4px;
    background: hsl(105, 14%, 40%);
    align-self: start;
    border-radius: 50%;

    img {
        width: 32px;
    }
}
.HudButton {
    position: relative;
    font-weight: bold;
    background: none;;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    border: none;
    padding-bottom: 24px;
    margin-top: 4px;

    background: #22222255;

    &:first-child { margin-top: -6px; }
}
.HudButton_Icon {
    display: block;
    padding: 7px 6px 5px 6px;
    border: 1px solid white;
    border-radius: 50%;
    // margin-bottom: 6px;
}
.HudButton_Label {
    position: absolute;
    display: block;
    white-space: nowrap;
    bottom:6px;
}

.VictoryHUD {

    background-color: rgba(#222, 0.75);

    display: flex;
    justify-content: center;
    align-items: center;
}



.VictoryMessage {
    color: #222;
    padding: 24px 48px;
    background: white;
    border-radius: 4px;

    text-align: center;
    
    h2 { margin-bottom: 12px; }
    p { margin-bottom: 12px; }
}

.CoverOverlay {
    position: fixed;
    display: flex;
    width: 100%; height: 100%;
    padding: 12px;
    
    justify-content: center;
    align-items: center;

    background-color: rgba(#222, 0.75);
    backdrop-filter: 12px;

    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease;
}

.CoverOverlay--show {
    pointer-events: initial;
    opacity: 1;
}

.CoverOverlay_Image {
    object-fit: contain;
    max-width: 100%;
}

</style>