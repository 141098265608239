<template>
    <i
        class="Icon"
        :style="`-webkit-mask-image: url(${url}); mask-image: url(${url})`"
    />
</template>

<script>
export default {
    props: [ "name", "type" ],

    computed: {
        url() {
            let type = this.type || "outline";
            if (type == "fill") return `/eva-icons/fill/svg/${this.name}.svg`;
            if (type == "outline") return `/eva-icons/outline/svg/${this.name}-outline.svg`;

            debugger;
        }
    }
}
</script>

<style lang="scss">

.Icon {
    display: inline-block;
    width: 1em; height: 1.1em;
    margin: 0;
    vertical-align: middle;
    background-color: currentColor;
    mask-size: contain;
    mask-repeat: no-repeat;

    font-size: 18px;
    line-height: 0;
}

</style>