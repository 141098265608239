<template>
    <header class="TopBar">
        <router-link class="TopBar_Item TopBar_Item--left" to="/"><icon name="home" /></router-link>

        <h1 class="TopBar_Item TopBar_Item--center PageTitle">COLLECTION</h1>

        <router-link to="/collections" class="TopBar_Item TopBar_Item--right">MORE<icon name="chevron-right" /></router-link>
    </header>
    <main class="view view--create">

        <h2 class="ContentTitle">{{ collection.title }}</h2>
        <p>
            {{ collection.description }}
        </p>

        <aside class="FilterBar">
            <span>{{ works.length }} works;</span>

            <router-link :to="`/collections/${collection.slug}/${randomWork?.artObject.objectNumber}`">
                <icon name="shuffle-2"/>random
            </router-link>
            <router-link to="/collections">Other collections</router-link>
        </aside>

        <ul class="WorkList">
            <li v-for="work in works" :key="work.artObject.id" class="WorkTile">
                <router-link :to="`/collections/${collection.slug}/${work.artObject.objectNumber}`">
                    <figure>
                        <img v-if="work.artObject.webImage" style="width: 100%; height: auto"
                            :src="`/256_auto/${work.artObject.objectNumber}.jpg`"
                            :width="work.artObject.webImage.width"
                            :height="work.artObject.webImage.height" />
                        
                        <figcaption>
                            {{getTitle(work.artObject)}}
                        </figcaption>

                    </figure>
                </router-link>
            </li>
        </ul>
    </main>
</template>

<script>
import { ref, toRefs, toRef, reactive, onMounted, onUnmounted, watch, computed} from 'vue';

import Camera from "../game/camera.js";
import * as Input from "../game/input.js";
import * as Puzzle from "../game/puzzle.js";
import { getTitle } from "../lib/works.js";


import data from "../assets/data/data.json";
import collections from "../assets/data/collections.json";

window.data = data;

export default {
    name: "CollectionView",
    props: [ "slug" ],
    setup(props, ctx) {

        let collection = computed(() => collections.find(c => c.slug == props.slug));
        let works = computed(() => collection.value.ids.filter(id => id in data).map(id => data[id]));
        let randomWork = computed(() => pickRandom(works.value ?? []));

        return { collection, works, randomWork, getTitle };
    },
}
</script>

<style lang="scss">

.FilterBar {
    position: sticky;
    top: 12px;
    padding: 6px 12px;
    width: 100%;
    margin-top: 12px;
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: 8px;
    align-items: baseline;
    max-width: 1004px;


    box-shadow: 0 4px 8px -4px;

    color: black;
    background: rgba(palegoldenrod, 0.75);
    border-radius: 4px;
    font-variant: small-caps;
    text-transform: lowercase;

    & > *:not(.foo) {
        color: black;
        padding: 4px;
        border-radius: 2px;
    }
    & > a:hover, & > button:hover, & > :focus {
        background: rgba(white, .5);
    }
    & > :active:not(b) {
        background: rgba(white, 1);
    }

    & .Icon {
        font-size: inherit;
        margin: 0 0.1em;
    }
}


.WorkList {
    column-count: 2;
    margin-top: 12px;
}

.WorkTile {
    break-inside: avoid;
    padding: 6px;
    margin: 12px 0;
    background: #ffffffee;
    border-radius: 4px;
    border-bottom: 2px solid black;

    &:first-child { margin-top: 0; }

    a {
        color: black;
        text-decoration: none;
    }

    figcaption {
        margin: 6px 0;
        font-size: 12px;
        line-height: 1.2;
    }
}



</style>