const sfxBasePath = "/sound";
const sfxMap = {
  // 69: "leon/69.wav",
  acoustic_fit: "Akoestiek/Fits.wav",
  acoustic_no_fit: "Akoestiek/Does not fit.wav",
  acoustic_shuffle: "Akoestiek/Schuif.wav",
  acoustic_shuffle2: "Akoestiek/Schuif 2.wav",
  // acoustic_pleur: "Akoestiek/Pleur de hele puzzel op tafel.wav",
  // acoustic_pleur2: "Akoestiek/Pleur de hele puzzel op tafel v2.wav",
  digital_fit: "Digitaal/Digital Correct.wav",
  digital_no_fit: "Digitaal/Digital incorrect.wav",
};

const loadSoundFile = (ctx, path) => {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", path, true);
    xhr.responseType = 'arraybuffer';
    xhr.onload = () => ctx.decodeAudioData(xhr.response, resolve, reject);
    xhr.onerror = reject;
    xhr.send();
  });
}

export const initSound = () => {

  let cfg = { master: 1, ui: 1, game: 1, bg: 0.2 };
  let audioConfigString = localStorage["masterpieces_audio_config"];
  try { cfg = JSON.parse(audioConfigString); } catch { }

  const AudioContext = window.AudioContext || window.webkitAudioContext;
  if (true || !AudioContext) {
    return [ null, Promise.resolve() ];
  }
  const ctx = new AudioContext();
  const master = ctx.createGain();
  master.gain.setValueAtTime(cfg.master, 0);
  master.connect(ctx.destination);
  // master.gain.value = 10.;

  const bgGain = ctx.createGain();
  bgGain.gain.setValueAtTime(cfg.bg, 0);
  bgGain.connect(master);
  
  const gameGain = ctx.createGain();
  gameGain.gain.setValueAtTime(cfg.game, 0);
  gameGain.connect(master);
  
  const uiGain = ctx.createGain();
  uiGain.gain.setValueAtTime(cfg.ui, 0);
  uiGain.connect(master);

  const audio = {
    ctx, master, bgGain, gameGain, uiGain, cfg,
    sfx: null,
  };


  const sfxKeys = Object.keys(sfxMap);
  const sfxPromise = Promise.all(sfxKeys.map(key => loadSoundFile(ctx, `${sfxBasePath}/${sfxMap[key]}`)))
    .catch((err) => {
      console.error("could not load audio", err);
      return [];
    })
    .then(buffers => {
      let sfx = {};
      buffers.forEach((buf, i) => sfx[sfxKeys[i]] = buf);
      audio.sfx = sfx;
      return audio;
    });

  return [ audio, sfxPromise ];
};
// let [ audio, audioLoad ] = initSound();

export const playSFX = window.playSFX = (sound, fx, loop=false, parent=false) => {
  if (!sound) return;

  const { ctx, master, sfx } = sound;

  if (!sfx[fx]) {
    console.warn("could not play soud", fx);
    return;
  }
  
  
  const source = ctx.createBufferSource();
  if (loop) {
    source.loop = true;
    if (Array.isArray(loop)) {
      source.loopStart = loop[0];
      source.loopEnd = loop[1];
    }
  }
  source.buffer = sfx[fx];
  source.connect(parent || master);
  source.start(0);

  return source;
}


export const playPlop = (sound, dt = 0) => {
  // playSFX(sound, "plop");

  // return;

  let { ctx, master } = sound;
  const startT = ctx.currentTime + dt;
  const   endT = startT + 0.05;

  const ctrl = ctx.createGain();
  ctrl.gain.value = 0.05 + 0.01*(2*random() - 1);
  ctrl.gain.exponentialRampToValueAtTime(0.12, endT);

  ctrl.connect(master);


  const oscillator = ctx.createOscillator();
  oscillator.frequency.value = 200;
  oscillator.type = "sawtooth";
  oscillator.detune.value = random()*1000;

  oscillator.start(startT);
  oscillator.frequency.linearRampToValueAtTime(20, endT);
  oscillator.stop(endT);

  oscillator.connect(ctrl);
}