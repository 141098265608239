import * as Utils from "./utils.js";


///
/// Input
///

export const BUTTONS = {
    NONE: 0,
    LEFT:    1 << 0,
    RIGHT:   1 << 1,
    MIDDLE:  1 << 2,
    BACK:    1 << 3,
    FORWARD: 1 << 4,
};

export let currentMouse = { p: [ 0, 0 ], wheel: 0, buttons: BUTTONS.NONE };
export let lastMouse = { p: [ 0, 0 ], wheel: 0, buttons: BUTTONS.NONE };

let handlers = {
    "wheel":     e => currentMouse.wheel += e.deltaY,
    "mousemove": e => currentMouse.p = [ e.clientX, e.clientY ],
    "mousedown": e => currentMouse.buttons = e.buttons,
    "mouseup":   e => currentMouse.buttons = e.buttons,

    "touchmove": e => {
        currentMouse.p = [ e.touches[0].clientX, e.touches[0].clientY ];
        if (e.target == document.body) e.preventDefault();
    },
    "touchstart": e => {
        currentMouse.buttons = BUTTONS.LEFT;
        currentMouse.p = [ e.touches[0].clientX, e.touches[0].clientY ];
        if (e.target == document.body) e.preventDefault();
    },
    "touchend": e => {
        currentMouse.buttons = BUTTONS.NONE;
        if (e.target == document.body) e.preventDefault();
    },
    "touchcancel": e => {
        currentMouse.buttons = BUTTONS.NONE;
        if (e.target == document.body) e.preventDefault();
    },
}



export function initialize() { Utils.registerEventHandlers(handlers); };
export function destroy() { Utils.removeEventHandlers(handlers); };

export function getDelta() {
    return {
        p: sub(currentMouse.p, lastMouse.p),
        wheel: currentMouse.wheel - lastMouse.wheel,
        buttons: currentMouse.buttons ^ lastMouse.buttons
    };
};

export function endFrame() {
    lastMouse = currentMouse;
    currentMouse = {
        ...currentMouse,
        p: [ ...currentMouse.p ]
    };
};