import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Collection from '../views/Collection.vue';
import CollectionList from '../views/CollectionList.vue';
import Work from '../views/Work.vue';
import Config from '../views/Config.vue';
import Puzzle from '../views/Puzzle.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/config',
    name: 'Config',
    component: Config
  },
  {
    path: '/collections',
    name: 'CollectionList',
    component: CollectionList
  },
  {
    path: '/collections/:slug',
    name: 'Collection',
    props: true,
    component: Collection
  },
  {
    path: '/collections/:collectionSlug/:workId',
    name: 'Work',
    props: true,
    component: Work
  },
  {
    path: '/puzzle/:id',
    name: 'Puzzle',
    props: true,
    component: Puzzle
  },
  {
    path: '/about',
    name: 'About',
    component: About
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    window.scrollTo(0, 0);
    return { x: 0, y: 0 }
  }
  
})

export default router
