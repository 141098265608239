import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import Icon from "./components/Icon.vue";

import "./assets/main.scss";


let app = createApp(App);

app.component("Icon", Icon);

app.use(store).use(router).mount('#app')
