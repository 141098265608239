<template>
    <header class="TopBar">
        <router-link to="/" class="TopBar_Item TopBar_Item--left"><icon name="home" /></router-link>

        <h1 class="TopBar_Item TopBar_Item--center PageTitle">COLLECTIONS</h1>
    </header>
    <main class="view view--collectios-list">
        <ul>
            <!-- For some reason router-link tag="li" doesn't work..? -->
            <li
                @click="goTo(col.slug)"
                v-for="(col, i) in collections" :key="col.id"
                class="CollectionTile"
            >
                <img class="CollectionTile_Background" :src="`/256_auto/${images[i]}.jpg`" />
                <span class="CollectionTile_Title">{{ col.title }}</span>
            </li>
        </ul>
    </main>
</template>

<script>


import data from "../assets/data/data.json";
import collections from "../assets/data/collections.json";

export default {
    setup(props, ctx) {

        let images = collections.map(col => {
            let workId, work;
            while (!work) {
                workId = pickRandom(col.ids);
                work = data[workId];
            };

            return workId;
        });
        return { collections, images };
    },
    methods: {
        goTo(slug) { return this.$router.push(`/collections/${slug}`); },
        
    }
}
</script>

<style lang="scss">


.CollectionTile {
    position: relative;
    background: white;
    height: 240px;
    color: #222;
    margin-bottom: 12px;
    padding: 24px;
    width: 360px;
    border-radius: 4px;

    font-size: 36px;
    text-align: center;
    overflow: hidden;
}

.CollectionTile_Background {
    position: absolute;
    top: 50%; left: 0;
    display: block;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    opacity: .7;
    transform: translateY(-50%);
    // object-fit: contain;
}

.CollectionTile_Title {
    position: relative;
    font-weight: bold;
}

</style>