export const getTitle = (work) => {
  console.log(work);
  let titles = [
      work.title,
      work.longTitle,
      work.label.title,
      ...work.titles
  ];
  titles = titles.filter(t => t);

  let aka = titles
      .map((t) => (/known as .([\w\s’']+)[’']$/i).exec(t)?.[1])
      .find((t) => t);

  let result = aka;
  if (!result) {
      titles.sort((a, b) => a.length - b.length);
      result = titles[0];        
  }

  return result;
}