<template>
  <header class="TopBar">
      <!-- <router-link class="TopBar_Item TopBar_Item--left" to="/"><icon name="home" /></router-link> -->

      <h1 class="TopBar_Item TopBar_Item--center PageTitle"><img src="/logo.svg" class="logo" alt="MasterPieces" /></h1>

      <router-link to="/config" class="TopBar_Item TopBar_Item--right"><icon name="settings-2" /></router-link>
  </header>
  <div class="view view--home">
    <section
      v-if="inProgressGames.length"
      class="ContinueSec_wrapper"
    >
      <h3>Continue</h3>
      <puzzle-tile :game="inProgressGames[0]" />
    </section>

    <section>
      <h3 v-if="games.length == 0">
        Get started
      </h3>
      <router-link class="btn NewPuzzleBtn" to="/collections/masterpieces-of-the-rijksmuseum">
        🧩 new puzzle
      </router-link>
    </section>


    <section
      v-if="recentGames.length"
      class="RecentPuzzle_wrapper"
    >
      <h3>More recent puzzles</h3>
      <ul class="RecentPuzzle_list">
        <li
          v-for="game in recentGames" :key="game.id"
          class="RecentPuzzle_item"
        >
          <puzzle-tile :game="game" variation="small" />
        </li>
      </ul>
    </section>

    <section v-if="finishedGames.length">
      <h3>Finished puzzles</h3>
      <ul class="FinishedPuzzle_List">
        <li
          class="FinishedPuzzle_Item"
          v-for="game in finishedGames" :key="game.id"
        >
          <puzzle-tile :game="game" />
        </li>
      </ul>
    </section>

  </div>
</template>

<script>
// @ is an alias to /src
import PuzzleTile from '@/components/PuzzleTile.vue';

import * as Puzzle from "@/game/puzzle.js";

import works from "@/assets/data/data.json";

export default {
  name: 'Home',
  components: {
    PuzzleTile
  },
  setup() {

    try {
      let gameIds = JSON.parse(localStorage["masterpieces_games"] || "[]");

      // gameIds.forEach(id => {
      //   Puzzle.load(id)
      //     .then(({puzzle, chunks}) => Puzzle.save(puzzle, chunks));
      // });

      let games = gameIds.map(id => JSON.parse(localStorage[`masterpieces_meta__${id}`]));
      games.sort((a, b) => b.lastChange - a.lastChange)
      let finishedGames = games.filter(g => g.completion == 1);
      let inProgressGames = games.filter(g => g.completion < 1);
      let recentGames = inProgressGames.slice(1);

      // recentGames = [ ...recentGames, ...recentGames, ...recentGames, ...recentGames, ...recentGames, ...recentGames, ...recentGames, ];

      return {
        gameIds, games, finishedGames, inProgressGames, recentGames, works,
      };
    } catch {
      Object.keys(localStorage).forEach(key => {
        if (key.startsWith("masterpieces_")) {
          localStorage.removeItem(key);
        }
      });
      location.reload();
    }
  }
}
</script>

<style lang="scss">

.view--home section {
  margin: 12px 0;
}

.RecentPuzzle_wrapper {
  width: 300px;
  
}
.RecentPuzzle_list {
  padding: 0 12px;
  margin: 0 -12px;
  display: flex;
  max-width: 340px;
  overflow: auto;
  mask-image: linear-gradient(to right, transparent 0, white 12px, white 300px, transparent);
  // margin: 0 -12px;

  &:after {
    content: "";

    display: block;
    width: 4px;
    height: 120px;
    flex: 0 0 auto;
  }
}

.RecentPuzzle_item {
  margin-right: 8px;
  flex: 0 0 auto;
}

.FinishedPuzzle_Item:not(:first-child) {
  margin-top: 8px;
  flex: 0 0 auto;
}
</style>