export function generateRegularMesh(dim, gridDim, perturb = 0, seed = null) {
  let cellDim = div(dim, gridDim);
  let vertices = [];
  let faces = [];

  let randomSource = seed ? xmur3(seed) : random;

  for (let y = 0; y <= gridDim[Y]; ++y) {
    for (let x = 0; x <= gridDim[X]; ++x) {
      let gridP = [ x, y ];
      let p = mul(gridP, cellDim);
      if (0 < x && x < gridDim[X] && 0 < y && y < gridDim[Y])
        p = add(p, scl(birandom2(randomSource), perturb));

      vertices.push(p);
    }
  }

  
  for (let y = 0; y < gridDim[Y]; ++y) {
    for (let x = 0; x < gridDim[X]; ++x) {
      let verts = [
        x + y*(gridDim[X] + 1),
        x + 1 + y*(gridDim[X] + 1),
        x + 1 + (y + 1)*(gridDim[X] + 1),
        x + (y + 1)*(gridDim[X] + 1)
      ];
      
      faces.push(verts);
    }
  }

  return { boundingDim: dim, vertices, faces };
}

export function generateCubicRegularMesh(dim, gridDim, perturb = 0, perturbControl = 0, seed = null) {
  let randomSource = seed ? xmur3(seed) : random; 
  
  let cellDim = div(dim, gridDim);
  let minDim = min(...cellDim);

  let vertCount = (gridDim[X] + 1)*(gridDim[Y] + 1);
  let vertices = new Array(vertCount);
  let edges = new Array(vertCount).fill().map(() => ({}));
  let faces = [];

  for (let y = 0; y <= gridDim[Y]; ++y) {
    for (let x = 0; x <= gridDim[X]; ++x) {
      let id = x + y*(gridDim[X] + 1);
      let gridP = [ x, y ];
      let p = mul(gridP, cellDim);
      if (0 < x && x < gridDim[X] && 0 < y && y < gridDim[Y])
        p = add(p, scl(birandom2(randomSource), perturb));

      vertices[id] = p;

      if (x < gridDim[X]) {
        let next = id + 1; // right
        let edge = {};
        edges[next][id] = edge;
        edges[id][next] = edge;
      }
      if (y < gridDim[Y]) {
        let next = id + (gridDim[X] + 1); // down
        let edge = {};
        edges[next][id] = edge;
        edges[id][next] = edge;
      }
    }
  }

  
  for (let y = 0; y < gridDim[Y]; ++y) {
    for (let x = 0; x < gridDim[X]; ++x) {
      let verts = [
        x + y*(gridDim[X] + 1),
        x + 1 + y*(gridDim[X] + 1),
        x + 1 + (y + 1)*(gridDim[X] + 1),
        x + (y + 1)*(gridDim[X] + 1)
      ];

      faces.push(verts);
    }
  }

  const mirror = (p1, p2) => add(p1, scl(sub(p2, p1), -1));

  const makeBlob = (start, end, minDim) => {
    let d = sub(end, start);
    minDim = minDim*0.8;

    let halfway = add(start, scl(d, 0.5 + 0.05*birandom(randomSource)));
    let nd = scl(normalize(d), minDim * 2/6);
    let perp = [ -nd[Y], nd[X] ];
    
    if(coin(0.5, randomSource)) perp = scl(perp,  -1);
    let halfperp = add(halfway, scl(perp, 1.5));

    let p0 = add(sub(halfway, scl(nd, 0.5)), scl(birandom2(randomSource), minDim*0.01));
    let p1 = add(add(p0, perp), scl(birandom2(randomSource), minDim*0.1));
    let p2 = add(add(p1, nd), scl(nd, birandom(randomSource)*0.1));
    let p3 = add(add(p0, nd), scl(nd, birandom(randomSource)*0.1));

    let c = 0.6 ;

    let p000 = mirror(p0, halfway);
    let p010 = halfway;
    let p121 = add(p1, scl(sub(halfperp, p1), c));
    let p011 = mirror(p1, p121);
    let p122 = add(p2, scl(sub(halfperp, p2), c));;
    let p232 = mirror(p2, p122);
    let p233 = halfway;
    let p333 = mirror(p3, halfway);

    return [ p000, p0, p010, p011, p1, p121, p122, p2, p232, p233, p3, p333 ];
  };

  edges.forEach((ends, startId) => {
    const start = vertices[startId];
    Object.keys(ends).forEach(endId => {
      let edge = ends[endId];
      if (!edge[startId]) {
        const end = vertices[endId];
        let d = sub(end, start);
        
        
        let shouldPerturb = true;
        if (abs(startId - endId) == 1) {
          let y = floor(startId/(gridDim[X] + 1));
          shouldPerturb = !(y == 0 || y == gridDim[Y]);
        } else {
          let x = startId%(gridDim[X] + 1);
          shouldPerturb = !(x == 0 || x == gridDim[X]);
        }
        if (shouldPerturb) {
          let c0 = add(start, scl(d, 1/6));
          let c1 = add(start, scl(d, 5/6));
          c0 = add(c0, scl(birandom2(randomSource), perturbControl));
          c1 = add(c1, scl(birandom2(randomSource), perturbControl));

          let blob = makeBlob(start, end, minDim);
          edge[startId] = [ c0, ...blob, c1, end ];
          blob.reverse();
          edge[endId] = [ c1, ...blob,  c0, start ];
        } else {
          // this repetition of corners helps balance the centroid.
          edge[startId] = [ start, start, start, start, start, start, end, end, end, end, end, end ];
          edge[endId] = [ end, end, end, end, end, end, start, start, start, start, start, start ];

        }

      }
    });
  });

  return { boundingDim: dim, vertices, faces, edges };
}