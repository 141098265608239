import * as Utils from "./utils.js";

export let transform = [
  1, 0, 0,
  0, 1, 0,
  0, 0, 1
];
export let project = (p, t) => [
  p[X]*t[0] + p[Y]*t[1] + t[2],
  p[X]*t[3] + p[Y]*t[4] + t[5],
];
export let unproject = ([x1, y1], t) => {
  let [
    t0, t1, t2,
    t3, t4, t5,
    t6, t7, t8
  ] = t;

  let x0, y0;
  if (abs(t0) > abs(t3)) {
    let _y = -((t3*(x1 - t2))/t0 + t5)/(t4 - t3*t1/t0);
    x0 = -(t1*_y + t2 - x1)/t0;
  } else {
    let _x = -((t0*(y1 - t5))/t3 + t2)/(t1 - t0*t4/t3);
    x0 = -(t4*_x + t5 - y1)/t3;
  }

  if (abs(t1) > abs(t4)) {
    let _y = -((t4*(x1 - t2))/t1 + t5)/(t3 - t0*t4/t1);
    y0 = -(t0*_y + t2 - x1)/t1;
  } else {
    let _x = -((t1*(y1 - t5))/t4 + t2)/(t0 - t3*t1/t4);
    y0 = -(t3*_x + t5 - y1)/t4;
  }

  return [ x0, y0 ];
};
export let zoom = s => transform = mul3x3(transform, [
  s, 0, 0,
  0, s, 0,
  0, 0, s
]);
export let zoomAt = (p, s) => {
  translate(scl(p, -1));
  zoom(s);
  translate(scl(p, 1));
};
export let zoomInPlace = s => transform = [
    s*transform[0], s*transform[1],   transform[2],
    s*transform[3], s*transform[4],   transform[5],
    s*transform[6], s*transform[7], s*transform[8]
  ];
export let translate = ([ x, y ]) => {
  transform[2] += x;
  transform[5] += y;
};
export let rotate = a => transform = mul3x3(transform, [
  cos(a),-sin(a), 0,
  sin(a), cos(a), 0,
       0,      0, 1
]);
export let getDeterminant = () => abs(transform[0] + transform[4] - transform[1] - transform[3]);


let canvas;
export let viewDim;
let handleResize = e => {
  let box = canvas.getBoundingClientRect();
  viewDim = [ canvas.width, canvas.height ] = [ box.width, box.height ];
};

export const fitScreen = puzzleMesh => {
  transform = [
    1, 0, 0,
    0, 1, 0,
    0, 0, 1
  ];
  zoom(min(...div(viewDim, scl(puzzleMesh.boundingDim, 1.1))));
  translate(scl(viewDim, 0.5));
}

export const initialize = (_canvas, puzzleMesh) => {
  canvas = _canvas;
  Utils.registerEventHandlers({ "resize": handleResize });

  handleResize();
  fitScreen(puzzleMesh);
}

export const update = (ctx, input) => {
  let { mouse, mouseDelta } = input;
  if (mouseDelta.wheel) {
    zoomAt(mouse.p, 1 + mouseDelta.wheel/200);
  }
}

export const destroy = () => { Utils.registerEventHandlers({ "resize": handleResize }); };


export const setTransform = t => transform = t; 

window.getTransform = () => transform;