import * as Camera from "./camera.js";
import * as Input from "./input.js";
import * as Sound from "./sound.js";
import * as Mesh from "./mesh-generators.js";

import works from "../assets/data/data.json";

let angleFudge = TAU/40;
let distanceFudge = 20;

let dialR = 15;
let dialOffset = 120;
let dialA = -TAU/4;
const getDialP = chunk => add(chunk.p, [ 0, -dialOffset/Camera.getDeterminant() ]);

const getPuzzlePieceCount = (puzzle) => puzzle.mesh?.faces.length ?? puzzle.meshSpec.gridDim[X]*puzzle.meshSpec.gridDim[Y]

export const calculateCompletion = game => 1 - (game.chunks.length - 1)/(getPuzzlePieceCount(game.puzzle) - 1);

export function initializeChunks(pieceData) {
  return pieceData.map((pieceDescription, i) => {
    let chunk = {
      p: pieceDescription.p,
      a: 0,
      pieces: []
    };

    let piece = {
      chunk,
      description: pieceDescription,
      offset: [ 0, 0 ]
    };

    chunk.pieces.push(piece);

    return chunk;
  });
}
export function shuffleChunks(chunks, boundingDim) {
  chunks.forEach(chunk => {
    chunk.p = sub(mul(random2(), boundingDim), scl(boundingDim, 1/2));
    chunk.a = random()*TAU;
  });
}


export function buildPieceData(image, mesh) {
  let serializeEdge = (a, b) => `${a.join()}_${b.join()}`;
  let facesByEdge = new Map();
  mesh.faces.forEach((face, id) => {
    for(let i = 0; i <= face.length; ++i) {
      let a = mesh.vertices[face[saw(i, face.length)]];
      let b = mesh.vertices[face[saw(i + 1, face.length)]];

      let ab = serializeEdge(a, b);
      let ba = serializeEdge(b, a);

      if (!facesByEdge.has(ab)) facesByEdge.set(ab, []);
      if (!facesByEdge.has(ba)) facesByEdge.set(ba, []);

      facesByEdge.get(ab).push(id);
      facesByEdge.get(ba).push(id);
    }
  });


  let atlasMaxCol = 0, atlasMaxRow = 0;

  let pieceData = [];
  mesh.faces.forEach((face, id) => {
    let polygon = face.map(v => mesh.vertices[v]);
    let boundingPolygon = [ ...polygon ];

    if (mesh.edges) {
      boundingPolygon = [];
      face.forEach((startId, i) => {
        let endId = face[(i + 1)%face.length];
        let edge = mesh.edges[startId][endId];

        boundingPolygon.push(mesh.vertices[startId]);
        boundingPolygon.push(...edge[startId]);
        // boundingPolygon.push(mesh.vertices[endId]);
      });
    }

    let p = scl(boundingPolygon.reduce(add, [ 0, 0 ]), 1/boundingPolygon.length);
    let relPolygon = polygon.map(v => sub(v, p));
    let relBoundingPolygon = boundingPolygon.map(v => sub(v, p));

    let maxP = relBoundingPolygon.reduce((p, v) => lenSqr(p) < lenSqr(v) ? v : p, [ 0, 0 ]);
    let minV = relBoundingPolygon.reduce(([px, py], [vx, vy]) => [min(px, vx), min(py, vy)], [0, 0]);
    let maxV = relBoundingPolygon.reduce(([px, py], [vx, vy]) => [max(px, vx), max(py, vy)], [0, 0]);

    let boundingDim = sub(maxV, minV);
    atlasMaxCol = max(atlasMaxCol, boundingDim[X]);
    atlasMaxRow = max(atlasMaxRow, boundingDim[Y]);

    const pieceCanvas = document.createElement("canvas");
    pieceCanvas.width = boundingDim[X];
    pieceCanvas.height = boundingDim[Y];

    const pieceCtx = pieceCanvas.getContext("2d");

    pieceCtx.beginPath();
    let faceEdges = [];
    if (mesh.edges) {
      let offset = add(p, minV);
      let startVertice = sub(mesh.vertices[face[0]], offset);
      for (let i = 0, j = face.length - 1; i < face.length; j = i++) {
        faceEdges.push(mesh.edges[face[j]][face[i]][face[j]]);
      }
      faceEdges = faceEdges.flat().map(v => sub(v, offset));
      startVertice = sub(mesh.vertices[face[face.length - 1]], offset);
      pieceCtx.moveTo(startVertice[X], startVertice[Y]);
      for (let i = 0; i < faceEdges.length; i += 3) {
        pieceCtx.bezierCurveTo(
          ...faceEdges[i + 0],
          ...faceEdges[i + 1],
          ...faceEdges[i + 2],
        );
      }
    } else {
      pieceCtx.moveTo(relPolygon[0][X] - minV[X], relPolygon[0][Y] - minV[Y]);
      relPolygon.forEach(v => pieceCtx.lineTo(v[X] - minV[X], v[Y] - minV[Y]))
      pieceCtx.closePath();
    }

    pieceCtx.clip();
    pieceCtx.drawImage(image, -minV[X] - p[X], -minV[Y] - p[Y], mesh.boundingDim[X], mesh.boundingDim[Y]);

    let neighbors = [];
    for(let i = 0; i <= polygon.length; ++i) {
      let a = polygon[saw(i, polygon.length)];
      let b = polygon[saw(i + 1, polygon.length)];

      let ab = serializeEdge(a, b);
      let edgeFaces = facesByEdge.get(ab);
      neighbors.push(edgeFaces.find(n => n != id));
    }

    let piece = {
      id, p, neighbors,
      minV, maxV,
      boundingDim,
      boundingRadius: len(maxP),
      face: relPolygon,
      edges: faceEdges,
      boundingPolygon: relBoundingPolygon,
      tex: pieceCanvas,
    };

    pieceData.push(piece);
  });

  const atlasGridDiameter = ceil(sqrt(pieceData.length));
  const atlasCellDim = [ atlasMaxCol, atlasMaxRow ];
  const atlasApronDim = add(atlasCellDim, [ 2, 2 ]);
  const atlasGridDim = scl(atlasApronDim, atlasGridDiameter);
  
  const pieceAtlas = document.createElement("canvas");
  const atlasCtx = pieceAtlas.getContext("2d");
  [ pieceAtlas.width, pieceAtlas.height ] = atlasGridDim;

  let atlasCursor = [ 0, 0 ];
  pieceData.forEach((piece, i) => {
    
    let atlasP = mul(atlasCursor, atlasApronDim);

    atlasCtx.drawImage(piece.tex, ...atlasP);
    piece.atlas = {
      image: pieceAtlas,
      p: atlasP,
      dim: atlasCellDim,
    }

    delete piece.tex;

    atlasCursor[X]++;
    if (atlasCursor[X] >= atlasGridDiameter) {
      atlasCursor[Y]++;
      atlasCursor[X] = 0;
    }
  });


  return pieceData;
}



let isDialing = false;
let selectedChunk = null;
export let puzzleMesh, pieceData, chunks;
export let activeGame;
let lastSaved = Date.now();
let imgTag = new Image();

export let onConnect = (handler) => {

}


export function save(puzzle, chunks) {
  let chunkStorageFormat = chunks.map(chunk => ({
    ...chunk,
    pieces: chunk.pieces.map(piece => ({
      id: piece.description.id,
      offset: [ ...piece.offset ]
    }))
  }));

  lastSaved = Date.now();
  let storageData = {
    puzzle,
    chunks: chunkStorageFormat,
    lastChange: lastSaved
  };
  let metadata = {
    id: puzzle.id,
    work: puzzle.work,
    size: getPuzzlePieceCount(puzzle),
    completion: calculateCompletion({ puzzle, chunks }),
    lastChange: lastSaved
  }

  localStorage[`masterpieces_game__${puzzle.id}`] = JSON.stringify(storageData);
  localStorage[`masterpieces_meta__${puzzle.id}`] = JSON.stringify(metadata);
}
export function load(key) {
  let data = localStorage[`masterpieces_game__${key}`];
  if (!data) console.error("No game with given key");

  let parsed = JSON.parse(data);

  let work = works[parsed.puzzle.work];


  activeGame = window.activeGame = parsed.puzzle;
  puzzleMesh = parsed.puzzle.mesh;
  if (!puzzleMesh && parsed.puzzle.meshSpec) {
    console.log('rebuilding mesh');
    const { dim, gridDim, perturb, perturbControl, seed } = parsed.puzzle.meshSpec;
    puzzleMesh = Mesh.generateCubicRegularMesh(dim, gridDim, perturb, perturbControl, seed);
  }

  const imagePromise = new Promise((resolve, reject) => {
    imgTag.onload  = resolve;
    imgTag.onerror = reject;
  });
  imgTag.src = `/highres/${parsed.puzzle.work}.jpg`;


  return imagePromise.then(() => {
    pieceData = buildPieceData(imgTag, puzzleMesh);
    chunks = window.chunks = parsed.chunks;
    chunks.forEach(chunk => {
      chunk.pieces = chunk.pieces.map(piece => ({
        chunk,
        description: pieceData[piece.id],
        offset: piece.offset
      }));
    });

    return { puzzle: parsed.puzzle, chunks };
  });
}


let constructPiece = (ctx, p, a, d)  => {
  let edges = d.edges.map(v => add(p, rot(add(v, d.minV), a)));
  ctx.moveTo(...edges[edges.length - 1]);
  for (let i = 0; i < edges.length; i += 3) {
    ctx.bezierCurveTo(
      ...edges[i + 0],
      ...edges[i + 1],
      ...edges[i + 2],
    );
  }
}

export function updatePuzzle(ctx, sound, input) {
  let { mouse, lastMouse, mouseDelta, t, dt } = input;

  let wallT = Date.now();
  if (wallT - lastSaved > 30000) {
    console.log("saving progress");
    save(activeGame, chunks);
  }

  let shouldRerender = false;

  let mouseWorldP = Camera.unproject(mouse.p, Camera.transform);
  let lastMouseWorldP = Camera.unproject(lastMouse.p, Camera.transform);
  let mouseWorldDP = sub(mouseWorldP, lastMouseWorldP);

  let interaction = {
    type: "released",
    chunk: selectedChunk,
    dial: false
  };

  ctx.clearRect(0, 0, Camera.viewDim[X], Camera.viewDim[Y]);


  let hasWon = chunks.length == 1;
  if (hasWon) {
    // win state
    chunks[0].p = scl(chunks[0].p, 0.9);
    let targetA = chunks[0].a < PI ? 0 : TAU;
    chunks[0].a = lerp1(chunks[0].a, targetA, 0.1);

    let scale = 1;
    let boundingDim = activeGame.mesh.boundingDim;
    let viewAspect = Camera.viewDim[X] / Camera.viewDim[Y];
    let puzzleAspect = boundingDim[X] / boundingDim[Y];

    if (viewAspect < puzzleAspect) {
      scale = boundingDim[X]/Camera.viewDim[X];
    } else {
      scale = boundingDim[Y]/Camera.viewDim[Y];
    }

    let current = Camera.transform;
    Camera.fitScreen(puzzleMesh);
    Camera.zoomAt(scl(Camera.viewDim, 0.5), scale*0.4);
    let target = Camera.transform;

    Camera.setTransform(Camera.transform.map((c, i) => {
      return lerp1(target[i], current[i], 0.9);
    }));

  } else {

    Camera.update(ctx, input);


    if (mouseDelta.buttons & Input.BUTTONS.LEFT) {
      interaction.type = (mouse.buttons & Input.BUTTONS.LEFT) ? "grab" : "release";
    } else {
      interaction.type = (mouse.buttons & Input.BUTTONS.LEFT) ? "grabbed" : "released";
    }



    switch (interaction.type) {

      case "grab": {
        if (selectedChunk) {
          let dialP = getDialP(selectedChunk);
          if (dst(dialP, mouseWorldP) < dialR/Camera.getDeterminant())
            isDialing = true;
        }

        if (!isDialing) {
          selectedChunk = null;
          dialA = -TAU/4;
          chunks.forEach(chunk => {
            chunk.pieces.forEach(piece => {
              let p = add(chunk.p, rot(piece.offset, chunk.a));

              ctx.beginPath();
              constructPiece(ctx, p, chunk.a, piece.description);

              if (ctx.isPointInPath(...mouseWorldP))
                selectedChunk = chunk;
            });
          });
        }
        interaction.chunk = selectedChunk;

        if (selectedChunk) {
          let idx = chunks.indexOf(selectedChunk);
          chunks.splice(idx, 1);
          chunks.push(selectedChunk);
        }
      } break;

      case "grabbed": {
        if (isDialing) {
          let mouseA = v2a(sub(mouseWorldP, selectedChunk.p));
          let lastMouseA = v2a(sub(lastMouseWorldP, selectedChunk.p));

          let dA = mouseA - lastMouseA;
          selectedChunk.a = saw(selectedChunk.a + dA, TAU);
          dialA += dA;

        } else if (interaction.chunk) {
          interaction.chunk.p = add(interaction.chunk.p, mouseWorldDP);
        } else {
          Camera.translate(mouseDelta.p);
        }
      } break;

      case "release": {
        isDialing = false;

        if (interaction.chunk) {
          let chunk0 = interaction.chunk;
          let mergeChunk;
          let mergePiece;
          chunks.forEach(chunk1 => {
            const da = saw(chunk1.a - chunk0.a, TAU);
            if (chunk1 == chunk0
              || (da > angleFudge && da < TAU - angleFudge))
              return;

            chunk0.pieces.forEach(piece0 => {
              chunk1.pieces.forEach(piece1 => {
                if (piece0.description.neighbors.includes(piece1.description.id)) {
                  let p0 = add(chunk0.p, rot(piece0.offset, chunk0.a));
                  let p1 = add(chunk1.p, rot(piece1.offset, chunk1.a));

                  let avgA = (chunk0.a + chunk1.a)/2;
                  p0 = rot(p0, -avgA);
                  p1 = rot(p1, -avgA);

                  let dp = sub(piece0.description.p, piece1.description.p);
                  let expectedP = add(add(chunk1.p, rot(piece1.offset, chunk1.a)), rot(dp, chunk1.a));
                  let actualP = add(chunk0.p, rot(piece0.offset, chunk0.a));

                  if (dst(expectedP, actualP) < distanceFudge) {
                    mergeChunk = chunk1;
                    mergePiece = piece1;
                  }
                }
              });
            });
          });

          if (mergeChunk) {
            let pieceCentroid = [ 0, 0 ];
            let chunkPieces = [ ...chunk0.pieces, ...mergeChunk.pieces ]
              .map(piece => ({ ...piece }));

            chunkPieces.forEach(piece => {
              pieceCentroid = add(pieceCentroid, piece.description.p);
            });

            pieceCentroid = scl(pieceCentroid, 1/(chunkPieces.length));

            let chunk = {
              p: null,
              a: mergeChunk.a,
              pieces: chunkPieces,
            };
            chunkPieces.forEach(piece => {
              piece.chunk = chunk;
              piece.offset = sub(piece.description.p, pieceCentroid);
            });
            let updatedMergePiece = chunkPieces.find(piece => piece.description == mergePiece.description);

            let chunkDP = sub(mergePiece.offset, updatedMergePiece.offset);
            let chunkP = add(mergeChunk.p, chunkDP);
            chunk.p = chunkP;

            chunks.splice(chunks.indexOf(mergeChunk), 1);
            chunks.splice(chunks.indexOf(chunk0), 1);
            chunks.push(chunk);

            let e = new Event("MP_connect");
            window.dispatchEvent(e);

            Sound.playSFX(sound, "acoustic_fit");
          }
          else {
            let e = new Event("MP_drop");
            window.dispatchEvent(e);
            Sound.playSFX(sound, "acoustic_no_fit");
          }
        }
      } break;

      case "released": break;
    }
  }




  ///
  /// Rendering
  ///

  ctx.save();

  {
    let [
      t0, t1, t2,
      t3, t4, t5,
      t6, t7, t8
    ] = Camera.transform;
    ctx.setTransform(t0, t3, t1, t4, t2, t5);
  }

  if (hasWon) {
    ctx.save();
    let tl = scl(activeGame.mesh.boundingDim, -0.5);
    ctx.drawImage(imgTag, ...tl);

    ctx.rect(...tl, ...activeGame.mesh.boundingDim);
    ctx.clip();

    ctx.beginPath();
    let fxWidth = saw(2*t, 4000);
    ctx.arc(0, 0, fxWidth, 0, TAU);
    ctx.strokeStyle = "white";
    ctx.lineWidth = fxWidth;

    ctx.globalAlpha = (1 - fxWidth/4000)**2;
    ctx.stroke();

    ctx.restore();
  }

  ctx.strokeStyle = "black";
  ctx.lineWidth = 4;
  chunks.forEach((chunk, i) => {
    renderChunk(ctx, chunk, hasWon);
  });
  
  if (selectedChunk) {

    let chunk = selectedChunk;
    renderChunk(ctx, chunk, hasWon);
    
    ///
    /// Render dial
    ///
    
    let det = Camera.getDeterminant();

    ctx.fillStyle = "blue";
    ctx.beginPath();

    ctx.arc(...chunk.p, 10/det, 0, TAU);
    ctx.fill();
    
    let dialP = getDialP(chunk);

    ctx.lineCap = "round";


    /// Dial bg
    ctx.strokeStyle = "black";
    ctx.strokeStyle = "black";
    ctx.lineWidth = 14/det;

    ctx.beginPath();
    ctx.arc(...dialP, dialR/det, 0, TAU);
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(...chunk.p, dst(chunk.p, dialP), -PI/2 - 0.4, -PI/2 + 0.4);
    ctx.stroke();


    /// Dial fg
    ctx.lineWidth = 4/det;
    ctx.strokeStyle = isDialing ? "lightgray" : "white";
    ctx.fillStyle = isDialing ? "lightgray" : "white";
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(...dialP, dialR/det, 0, TAU);
    ctx.fill();

  }

  ctx.restore();
}

const renderChunk = (ctx, chunk, hasWon, selectedChunk = false, i = 0) => {
  ctx.save();

  ctx.translate(...chunk.p);
  ctx.rotate(chunk.a);

  chunk.pieces.forEach(piece => {
    let d = piece.description;

    let p = add(chunk.p, rot(piece.offset, chunk.a));
    if (false) { // debug visuals
      if (selectedChunk) {
        const da = abs(selectedChunk.a - chunk.a);
        if (!(da > angleFudge && da < TAU - angleFudge)) {
          ctx.strokeStyle = "yellow";
          ctx.lineWidth = 8;
        }
      }

      let poke = polygonContains(p, chunk.a, d.face, mouseWorldP);
      ctx.strokeStyle = poke ? "cornflowerblue" : "black";
      ctx.lineWidth = poke ? 8 : 4;
    }

    ctx.save();
    ctx.translate(piece.offset[X], piece.offset[Y]);

    if (false) { // debug visuals
      ctx.beginPath();
      ctx.strokeRect(d.minV[X], d.minV[Y], d.boundingDim[X], d.boundingDim[Y]);

      ctx.beginPath();
      ctx.moveTo(d.face[0][X], d.face[0][Y]);
      d.face.forEach(v => ctx.lineTo(v[X], v[Y]));
      ctx.closePath();
      ctx.stroke();
    }

    if (hasWon) {
      ctx.scale(0.99, 0.99);
      ctx.beginPath();
      constructPiece(ctx, [0,0], 0, d);
      ctx.clip();
      ctx.scale(1/0.99, 1/0.99);
    }

    // ctx.drawImage(d.tex, d.minV[X], d.minV[Y], d.boundingDim[X], d.boundingDim[Y]);
    ctx.drawImage(d.atlas.image, ...d.atlas.p, ...d.atlas.dim, ...d.minV, ...d.atlas.dim);
    ctx.restore();
  });
  ctx.restore();
};

window.maph = 312412432425;
window.pct = -0.8;

console.log('hello')